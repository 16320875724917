export const titlesSpinner = {
    charge_init: "Carga inicial ...",

    plant_save: "Guardando planta ...",
    plantSedes_add: "Agregando sedes ...",
    plantSedes_remove: "Retirando sedes ...",
    plantSedes_list: "Buscando las sedes del área ...",

    area_save: "Guardando área ...",
    area_plant_list: "Buscando áreas de la planta ...",
    areaPlants_add: "Asignado las áreas  ...",
    areaPlants_remove: "Retirando las áreas ...",

    subArea_save: "Guardando sub-área ...",
    subArea_area_list: "Buscando sub-áreas del área ...",
    subAreaArea_add: "Asignado las sub-áreas  ...",
    subAreaArea_remove: "Retirando las sub-áreas ...",

    lugar_save: "Guardando lugar ...",
    lugar_subArea_list: "Buscando lugares de la sub-área ...",
    lugarSubArea_add: "Asignado los lugares  ...",
    lugarSubArea_remove: "Retirando los lugares ...",

    comportamientoInseguro_save: "Guardando Comportamiento inseguro ...",
    condicionInsegura_save: "Guardando Condición insegura ...",
    condicionInsegura_list: "Buscando Condición insegura ...",
    accident_save: "Guardando accidente / incidente ...",

    categoyCondition_list: "Buscando categorias de tipo de condición ....",
    categoyCondition_save: "Guardando Categoria Condición insegura ...",
    categoyBehavior_list: "Buscando categorias de tipo de comportamiento ....",
    typeEvent_list: "Buscando los tipo de eventos ....",
    typeEventByArea_list: "Buscando tipo de evento por área ....",

    user_list: "Buscando usuarios ...",
    userPlants_add: "Agregando plantas ...",
    userPlants_remove: "Retirando plantas ...",
    user_save: "Guardando usuario ...",

    behavior_save: "Guardando Comportamiento ...",
    behavior_plant_list: "Buscando comportamientos de la planta ...",

    type_event_save: "Guardando tipo de evento ...",

    file_download: "Descagando archivo ...",

    search_workers: "Buscando trabajadores ...",

    inspection_save: "Guardando inspección ...",
    inspection_data_list: "Buscando datos de la inspección ...",

    report_inspection_generate: "Generando inspeccion ...",
};