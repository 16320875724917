import React from 'react'
import CustomInputInfoSingleLine from '../../../components/General/CustomInputInfoSingleLine';
import ReportService from '../../../../services/report';
import { CODES } from '../../../../utils/codesHTTP';
import DownloadIcon from '@mui/icons-material/Download';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const SEE_CINCO_S = ({ informationInspection, }) => {
    const options = informationInspection?.tipo?.opciones;
    const answers = informationInspection?.respuestas;
    const modules = informationInspection?.tipo?.tipo_modulos;

    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const dispatch = useDispatch();

    /* Esta funcion es para exportar excel

    const generateReport5S = async () => {
        try {
          //setTitleModalLoader('Generando Reporte de Sede...');
          handleOpenLoader(true);
          const result = await ReportService.generateReport5S(informationInspection);
          if (result.status === CODES.SUCCESS_200) {
            const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = "Inspección "+informationInspection?.tipo?.abreviatura+"_"+informationInspection?.id_inspeccion+"_"+informationInspection?.planta+"_"+informationInspection?.fecha+'.xlsx';
            link.click();
            URL.revokeObjectURL(url);
            //getPeople();
            
          } else {
              handleOpenLoader(false);
            //setTitleModalLoader(null);
          }
          handleOpenLoader(false);
          return []
        } catch (error) {
          console.log(`Error en generateReport5S: ${error}`);
        }
    }*/

    const generateReport5S = async () => {
        try {
            handleOpenLoader(true);
            const result = await ReportService.generateReport5S(informationInspection);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = "Inspección_" + informationInspection?.tipo?.abreviatura + "_" + informationInspection?.id_inspeccion + "_" + informationInspection?.planta + "_" + informationInspection?.fecha + '.pdf';
                link.click();
                URL.revokeObjectURL(url);
            } else {
                handleOpenLoader(false);
            }
            handleOpenLoader(false);
            return [];
        } catch (error) {
            console.log(`Error en generateReport5S: ${error}`);
        }
    }
    

    return (
        <section className='form-register inspection'>
            <div className='row'>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={'Area'}
                        value={informationInspection?.area}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={'Sub-Area'}
                        value={informationInspection?.subArea}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={"Fecha Inspección"}
                        value={informationInspection?.fecha}
                    />
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={"Auditor"}
                        name={"auditor"}
                        value={informationInspection?.evaluador || ""}
                    />
                </div>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={"Auditado"}
                        name={"auditado"}
                        value={informationInspection?.evaluado || ""}
                    />
                </div>
            </div>
            {/* preguntas */}
            <div className="table-container">
                <table className="table table-bordered table-sm custom-table">
                    {modules?.map((tipo_modulo) => {
                        const module = tipo_modulo?.modulo;
                        const questions = tipo_modulo?.preguntas;
                        const lengthQuestion = (tipo_modulo?.preguntas?.length ?? 0) + 1;
                        return (
                            <React.Fragment key={`module-${module?.id_modulo}`}>
                                <thead className="border-white">
                                    <tr>
                                        <th className="text-center title-table-inspection" style={{ width: "60px" }} rowSpan={2}>SENSO</th>
                                        <th className="text-center title-table-inspection d-none d-md-table-cell" style={{ width: "120px" }} rowSpan={2}>ÍTEM</th>
                                        <th className="text-center title-table-inspection d-md-none" style={{ width: "20px", fontSize: "0.8rem" }} rowSpan={2}>ÍTEM</th>
                                        <th className="text-center title-table-inspection col-2" style={{ width: "350px" }} rowSpan={2}>Descripción</th>
                                        <th className="text-center title-table-inspection" colSpan={5}>NOTA</th>
                                        <th className="text-center title-table-inspection" rowSpan={2}>OBSERVACIONES</th>
                                    </tr>
                                    <tr>
                                        {options?.map((row) => (
                                            <th key={row.label} className="text-center col-1" style={{ width: "30px", backgroundColor: row.desc2 }}>{row?.desc1}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={`module-${module?.id_modulo}`}>
                                        <td className="text-center title-table-inspection" style={{ width: "40px" }} rowSpan={lengthQuestion}>
                                            <label style={{ width: "50px", transform: 'rotate(-90deg)' }}>{module?.desModulo}</label>
                                        </td>
                                    </tr>
                                    {questions?.map((question) => {
                                        const pregunta_opcion = question.pregunta_opcion;
                                        const answer = answers?.find(e => +e.pregunta_id === +question.id_pregunta);
                                        return (
                                            <tr key={`question-${question?.id_pregunta}`}>
                                                <td className="text-center">{question.desPregunta}</td>
                                                <td className="text-center">{question.detallePregunta}</td>
                                                {options.map((opt) => {
                                                    const item = pregunta_opcion?.find(e => +e.opcion_id === +opt.value);
                                                    return (
                                                        <td
                                                            key={opt.value}
                                                            className={`text-center ${+answer?.opcion_id === opt.value ? 'selected' : ''}`}
                                                            style={{ cursor: 'pointer', backgroundColor: +answer?.opcion_id === +opt.value ? 'lightgreen' : 'inherit' }}
                                                        >
                                                            {item?.desripcion}
                                                        </td>
                                                    );
                                                })}
                                                <td className="text-center">
                                                    <input
                                                        className="form-control custom-input"
                                                        placeholder="Comentarios"
                                                        value={answer?.observaciones || ""}
                                                        readOnly
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </React.Fragment>
                        );
                    })}
                </table>
            </div>

            {/* resumen */}
            <div className="mb-5 col-12 col-md-4"
                style={{
                    border: "solid 1px black",
                    borderRadius: "25px",
                    padding: "10px",
                }}
            >
                {/* {modules?.map((tipo_modulo) => {
                    const module = tipo_modulo?.modulo;
                    return (
                        <span
                            style={{
                                fontSize: "13px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <label>
                                {`Promedio Nota Sentido de ${module.desModulo}:`}
                            </label>
                            <label style={{ fontWeight: "bold", }}>
                                {tipo_modulo.puntajeModulo}
                            </label>
                        </span>
                    )
                })} */}
                <span
                    style={{
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "solid 1px black"
                    }}
                >
                    <label>Nota 5S:</label>
                    <label style={{ fontWeight: "bold", }}>
                        {informationInspection?.puntaje}
                    </label>
                </span>
                <span
                    style={{
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <label>% Cumplimiento:</label>
                    <label style={{ fontWeight: "bold", }}>
                        {`${informationInspection?.porcentaje ? (informationInspection?.porcentaje * 100) : ''}%`}
                    </label>
                </span>
            </div>
            {/* Nueva sección similar a la última */}
            <div className="mb-5 col-12 col-md-4"
                style={{
                    border: "solid 1px black",
                    borderRadius: "25px",
                    padding: "10px",
                }}
            >
                <table className="table table-bordered table-sm custom-table">
                    <thead>
                        <tr>
                            <th className="text-center" colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>
                                Leyenda de Notas
                            </th>
                        </tr>
                        <tr>
                            {options?.map((row) => (
                                <th key={row.label} className="text-center col-1" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                    {row?.desc3}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {options?.map((row) => (
                                <td key={row.label} className="text-center" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                    {row?.label}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </section >
    )
}

export default SEE_CINCO_S;