import React from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox';
import CustomInputInfoSingleLine from '../../../components/General/CustomInputInfoSingleLine';
import ReportService from '../../../../services/report';
import { CODES } from '../../../../utils/codesHTTP';
import DownloadIcon from '@mui/icons-material/Download';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import GeneralService from '../../../../services/general';

const SEE_DTO = ({ idType, informationInspection, suggestionType, stateActionPlan, }) => {



    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const dispatch = useDispatch();
    // Agrupar sugerencias por tipoSugerencia_id
    const groupedSugerencias = suggestionType?.reduce((acc, tipo) => {
        acc[tipo.id_tipoSugerencia] = informationInspection?.sugerencias?.filter(sug => +sug.tipoSugerencia_id === +tipo.id_tipoSugerencia) || [];
        return acc;
    }, {});

    // Obtener la longitud máxima de sugerencias en cualquier grupo
    const maxRows = Math.max(...Object?.values(groupedSugerencias)?.map(group => group?.length));

    /*const showTopCompanyByPlant = async () => {
        try {
            setDataShift(null);
            const result = await GeneralService.showTopCompanyByPlant(valuePlant);
            setDataShift(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }*/

    const generateReportDTO = async () => {
        try {
            handleOpenLoader(true);
            const result = await ReportService.generateReportDTO(informationInspection);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = "Inspección_" + informationInspection?.tipo?.abreviatura + "_" + informationInspection?.id_inspeccion + "_" + informationInspection?.planta + "_" + informationInspection?.fecha + '.pdf';
                link.click();
                URL.revokeObjectURL(url);
            } else {
                handleOpenLoader(false);
            }
            handleOpenLoader(false);
            return [];
        } catch (error) {
            console.log(`Error en generateReportDTO: ${error}`);
        }
    }

    const extraStep = {
        id_pregunta: 'extra-step',
        desPregunta: 'Número de pasos',
        puntaje: "30.00",
        tipo_respuesta: {
            desTipoRespuesta: 'opt',
            opciones: [
                { value: 'Ok', label: 'Ok' },
                { value: 'NO', label: 'N/O' },
                { value: 'NA', label: 'N/A' },
            ],
            desc2: 'answer',
        },
    };

    return (
        <section className='form-register inspection'>
            <div className='row justify-content-center'>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={'Area'}
                        value={informationInspection?.area}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={'Sub-Area'}
                        value={informationInspection?.subArea}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col col-sm-12 col-md-6 col-lg-4 d-flex justify-content-evenly'>
                    <div style={{ width: "50%" }}>
                        <CustomInputInfoSingleLine
                            title={"Fecha Evaluación"}
                            value={informationInspection?.fecha}
                            name={"fecha"}
                        />
                    </div>
                    <div style={{ width: "20%" }}>
                        <CustomInputInfoSingleLine
                            type={"time"}
                            value={informationInspection?.hora}
                            name={"hora"}
                        />
                    </div>
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputInfoSingleLine
                        title={"PETS"}
                        value={informationInspection?.pets}
                        name={"pets"}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <CustomInputInfoSingleLine
                            title={"Evaluado"}
                            value={informationInspection?.evaluado}
                            name={"evaluado"}
                        />
                    </div>
                </div>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <CustomInputInfoSingleLine
                            title={"Supervisor del Evaluado"}
                            value={informationInspection?.supervisor}
                            name={"supervisor"}
                        />
                    </div>
                </div>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <CustomInputInfoSingleLine
                            title={"Evaluador"}
                            value={informationInspection?.evaluador}
                            name={"evaluador"}
                        />
                    </div>
                </div>
            </div>
            <div className='row justify-content-end'>
                <div className='col-sm-12 col-md-4 col-lg-2'>
                    <CustomInputInfoSingleLine
                        title={"Puntaje"}
                        value={informationInspection?.puntaje}
                        name={"puntaje"}
                    />
                </div>
            </div>
            {/* preguntas */}
            <div className="table-responsive">
                <table className="table table-bordered table-sm custom-table">
                    <thead className="border-white">
                        <tr>
                            <th className="text-center title-table-inspection" >Pasos Críticos da Tarea</th>
                            <th className="text-center title-table-inspection col-1" >Peso</th>
                            <th className="text-center title-table-inspection col-2" >OK?</th>
                            <th className="text-start title-table-inspection" >Anomalías presentadas y acciones correctivas necesarias</th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationInspection?.tipo?.tipo_modulos?.map((module) => {
                            return (
                                <ModuleRow
                                    key={`module-${module.modulo.id_modulo}`}
                                    module={module.modulo}
                                    questions={module.preguntas}
                                    informationInspection={informationInspection}
                                    onChangeRespuesta={null}
                                />
                            )
                        })}
                        <br /><br />
                        {/* Fila única con "Número de pasos" */}
                        {(+idType === 4) &&
                            <tr>
                                <td>{extraStep.desPregunta}</td>
                                <td className='text-center'>{extraStep.puntaje}</td>
                                <td>
                                    <CustomInputInfoSingleLine
                                        value={informationInspection?.pasos}
                                    />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {/* sugerencias */}
            <div className="table-responsive-sm">
                <table className="table table-bordered table-sm">
                    <thead className="border-white">
                        <tr>
                            {suggestionType?.map(tipo => (
                                <th key={tipo.id_tipoSugerencia} className="row-header-and-btn-end" style={{ background: "#BFBFBF", fontSize: "13px" }}>
                                    <div className="text-header">{tipo.desTipoSugerencia}</div>
                                    <button
                                        type='button'
                                        className="btn-outline-custom dark btn-header-end"
                                    >
                                        <AddBoxIcon fontSize='small' />
                                    </button>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: maxRows })?.map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {suggestionType?.map(tipo => (
                                    <th key={tipo.id_tipoSugerencia} className="row-header-and-btn-end">
                                        {groupedSugerencias[tipo.id_tipoSugerencia][rowIndex] ? (
                                            <>
                                                <div key={groupedSugerencias[tipo.id_tipoSugerencia][rowIndex]?.id_sugerencia} className="text-header">
                                                    {groupedSugerencias[tipo.id_tipoSugerencia][rowIndex]?.desSugerencia}
                                                </div>
                                                {/* <button type='button' className="btn-outline-custom danger btn-header-end">
                                                    <DeleteIcon style={{ fontSize: "15px" }} />
                                                </button> */}
                                            </>
                                        ) : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* plan de accion */}
            <div className="table-responsive-sm">
                <table className="table table-bordered table-sm">
                    <thead className="border-white">
                        <tr>
                            <th className="text-center title-table-inspection" colSpan={666} >
                                PLAN DE ACCIÓN
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center title-table-inspection" >SEMANA</th>
                            <th className="text-center title-table-inspection col-6" >ACCION</th>
                            <th className="text-center title-table-inspection" >FECHA FIN</th>
                            <th className="text-center title-table-inspection" >RESPONSABLE</th>
                            <th className="text-center title-table-inspection col-2 row-header-and-btn-end" >
                                <div className="text-header">ESTADO</div>
                                <button className="btn-outline-custom dark btn-header-end" type='button'>
                                    <AddBoxIcon fontSize='small' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationInspection?.planes_accion?.map((plan, idxPlan) => {
                            return (
                                <tr key={idxPlan}>
                                    <td className="text-center col-1">{plan.semana}</td>
                                    <td>{plan.desPlanAccion}</td>
                                    <td className="text-center">{plan.fechaFin}</td>
                                    <td className="text-center">{plan.responsable}</td>
                                    <td className="row-header-and-btn-end">
                                        <div className="text-header">{stateActionPlan?.find(e => +e.value === +plan.estadoPlan_id)?.label}</div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </section >

    )
}

const ModuleRow = ({ module, questions, informationInspection, onChange }) => {
    return (
        <>
            <tr key={`module-${module.id_modulo}`}>
                <td colSpan={999} style={{ background: "#BFBFBF", fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{`Asunto: ${module.desModulo}`}</td>
            </tr>
            {questions.map((question) => {
                const answer = informationInspection.respuestas.find(e => +e.pregunta_id === +question.id_pregunta);
                return (
                    <QuestionRow
                        key={`question-${question.id_pregunta}`}
                        question={question}
                        answer={answer}
                        onChange={onChange}
                    />
                );
            })}
        </>
    );
};

const QuestionRow = ({ question, answer, onChange }) => {
    const tipeRespuesta = question.tipo_respuesta;
    const options = question.opciones;
    return (
        <tr>
            <td>{question.desPregunta}</td>
            <td className='text-center'>{question.puntaje}</td>
            <td>
                {tipeRespuesta.desTipoRespuesta === 'opt' ?
                    <select
                        className="form-control custom-select"
                        onChange={(e) => onChange({ info: question, value: e.target.value, key: "answer" })}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        disabled
                    >
                        <option value="">Seleccione...</option>
                        {options.map((option) => {
                            return (
                                <option value={option.value}>{option.label}</option>
                            )
                        })}
                    </select>
                    : <input
                        className="form-control custom-input"
                        placeholder="Ingrese valor"
                        type={tipeRespuesta.desTipoRespuesta}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        readOnly
                        disabled
                    />
                }
            </td>
            <td>
                <input
                    className="form-control custom-input"
                    type="text"
                    value={answer?.anomalias || ""}
                    readOnly
                    disabled
                />
            </td>
        </tr>
    );
};

export default SEE_DTO;