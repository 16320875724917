export const errorsMessages = {
    plant_save: "Hubo un error al guardar la planta",
    area_save: "Hubo un error al guardar el área",
    subArea_save: "Hubo un error al guardar la sub-área",
    place_save: "Hubo un error al guardar el lugar",
    unsafe_behavior: "Hubo un error al guardar el comportamiento inseguro",
    accident: "Hubo un error al guardar el accidente / incidente",
    type_image: "Tipo de imagen no permitido",
    max_files_images: "Solo se permite 05 imagenes por reporte",
    file_download: "Hubo un error al descargar el archivo",
    report_information: "Error al generar información para el reporte",
};