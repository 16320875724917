import React, { useEffect, useState, } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import InspectionService from '../../../services/inspection';
import GeneralService from '../../../services/general';
import { simpleAlerts } from '../../../utils/alerts';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { titlesAlerts } from '../../../message/titlesAlerts';
import { errorsMessages } from '../../../message/errors';
import { CODES } from '../../../utils/codesHTTP';
import DynamicTitle from '../../../utils/DynamicTitle';
import DTO from './DTO/DTO';
import SST from './SST/SST';
import CINCO_S from './5S/CINCO_S';
import Swal from 'sweetalert2';
import AddWorker from '../../components/Workers/AddWorker/AddWorker';
import { validationMessages } from '../../../message/validations';

const Inspection = () => {
    const { codigo } = useParams();
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [openAddWorkers, setOpenAddWorkers] = useState(false);

    //* */
    const [informationType, setInformationType] = useState(null);
    const [informationInspection, setInformationInspection] = useState(null);

    const areas = useSelector(state => state.areas);
    const subAreas = useSelector(state => state.subAreas);

    const valuePlant = useSelector(state => state.valuePlant);

    const [dataAreas, setDataAreas] = useState(null);
    const [dataSubAreas, setDataSubAreas] = useState(null);

    const [valueArea, setValueArea] = useState(null);

    const [typeResponse, setTypeResponse] = useState([]);
    const [suggestionType, setSuggestionType] = useState([]);
    const [stateActionPlan, setStateActionPlan] = useState([]);

    useEffect(() => {
        setDataAreas(null);
        setDataSubAreas(null);

        setValueArea(null);
        if (+valuePlant > 0) {
            setDataAreas(areas?.filter((e) => +e.planta_id === +valuePlant));
        }
    }, [valuePlant, areas]);

    useEffect(() => {
        setDataSubAreas(null);
        if (+valuePlant > 0) {
            setDataSubAreas(subAreas?.filter((e) => +e.area_id === +valueArea));
        }
    }, [valueArea]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showTypeResponse(),
            showSuggestionType(),
            showStateActionPlan(),
        ]).then(() => handleOpenLoader(false));
    }

    const showTypeResponse = async () => {
        try {
            setTypeResponse([]);
            const result = await GeneralService.showTypeResponse();
            setTypeResponse(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    const showSuggestionType = async () => {
        try {
            setSuggestionType([]);
            const result = await GeneralService.showSuggestionType();
            setSuggestionType(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    const showStateActionPlan = async () => {
        try {
            setStateActionPlan([]);
            const result = await GeneralService.showStateActionPlan();
            setStateActionPlan(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    useEffect(() => {
        if (codigo) {
            getInformationType()
        }
    }, [codigo]);

    const getInformationType = async () => {
        try {
            setInformationType(null);
            const queryParameters = { codigo: codigo };
            const result = await InspectionService.getInformationType(queryParameters);
            if (result.status === CODES.SUCCESS_200) {
                setInformationType(result.data)
            }
            return [];
        } catch (error) {
            console.log(`Error en Information Type: ${error}`);
        }
    }

    useEffect(() => {
        if (informationType) {
            getInspection();
        }
    }, [informationType]);

    const getInspection = async () => {
        try {
            setInformationInspection(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.inspection_data_list);
            const queryParameters = { id_inspeccion: informationInspection?.id_inspeccion, tipo_id: informationType.id_tipo };
            // const queryParameters = { id_inspeccion: 1, tipo_id: informationType.id_tipo };
            const result = await InspectionService.getInspection(queryParameters);
            setInformationInspection({ ...result.data, tipo_id: informationType.id_tipo });
            return [];
        } catch (error) {
            console.log(`Error en Inspection: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const handleCancel = () => {
        setInformationInspection(null);
        getInspection();
    }

    const saveInspection = async (e) => {
        e.preventDefault();

        const resultSwal = await Swal.fire({
            title: titlesAlerts.confirm_inspection,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.inspection_save);
                const result = await InspectionService.createInspection(informationInspection);
                if (result.status === CODES.CREATE_201) {
                    simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                    getInspection();
                } else {
                    console.log(result?.response);
                    simpleAlerts({ message: errorsMessages.unsafe_behavior, type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en saveUnsafeCondition: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    const [workersSelected, setWorkersSelected] = useState([]);
    const [keyTemp, setKeyTemp] = useState(null);
    const handleOpenAddWorker = ({ key }) => {
        if (!valuePlant) {
            simpleAlerts({ message: validationMessages.planta, type: "warning" });
            return;
        }
        setOpenAddWorkers(true);
        setKeyTemp(key);
    }

    useEffect(() => {
        if (workersSelected && workersSelected?.length > 0) {
            setInformationInspection({ ...informationInspection, [keyTemp]: workersSelected[0]?.id_persona, [keyTemp.replace("_id", "")]: workersSelected[0] });
        }
    }, [workersSelected])

    return (
        <div>
            <DynamicTitle title={`Inspección ${informationType?.abreviatura}`} />
            <AddWorker
                open={openAddWorkers}
                setOpen={setOpenAddWorkers}
                typeSelection={"SINGLE"}
                valueTypeWorker={1}
                valuePlantAux={valuePlant}
                workersSelected={workersSelected}
                setWorkersSelected={setWorkersSelected}
            />
            <h1 className='text-center title-page'>{informationType?.desTipo}</h1>
            <form className="px-3"
                onSubmit={saveInspection}
            >
                {(+informationType?.formato_id === 1) ?
                    <DTO
                        codigo={codigo}
                        handleCancel={handleCancel}
                        handleOpenAddWorker={handleOpenAddWorker}
                        valuePlant={valuePlant}
                        dataAreas={dataAreas}
                        valueArea={valueArea}
                        setValueArea={setValueArea}
                        dataSubAreas={dataSubAreas}
                        suggestionType={suggestionType}
                        stateActionPlan={stateActionPlan}
                        informationType={informationType}
                        getInspection={getInspection}
                        informationInspection={informationInspection}
                        setInformationInspection={setInformationInspection}
                        handleOpenLoader={handleOpenLoader}
                        
                    />
                    : (+informationType?.formato_id === 2) ?
                        <SST
                            handleCancel={handleCancel}
                            handleOpenLoader={handleOpenLoader}
                            handleOpenAddWorker={handleOpenAddWorker}
                            valuePlant={valuePlant}
                            dataAreas={dataAreas}
                            valueArea={valueArea}
                            setValueArea={setValueArea}
                            dataSubAreas={dataSubAreas}
                            suggestionType={suggestionType}
                            stateActionPlan={stateActionPlan}
                            informationType={informationType}
                            getInspection={getInspection}
                            informationInspection={informationInspection}
                            setInformationInspection={setInformationInspection}
                        />
                        : (codigo === '5S-AREA-ADMIN' || codigo === '5S-PLANTA') ?
                            <CINCO_S
                                handleCancel={handleCancel}
                                handleOpenAddWorker={handleOpenAddWorker}
                                valuePlant={valuePlant}
                                dataAreas={dataAreas}
                                valueArea={valueArea}
                                setValueArea={setValueArea}
                                dataSubAreas={dataSubAreas}
                                suggestionType={suggestionType}
                                stateActionPlan={stateActionPlan}
                                informationType={informationType}
                                getInspection={getInspection}
                                informationInspection={informationInspection}
                                setInformationInspection={setInformationInspection}
                            />
                            : <h1 className='text-center title-page'>Pagina en mantenimiento</h1>
                }
            </form>
        </div>
    )
}

export default Inspection;