import React, { useEffect, useState, } from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { simpleAlerts } from '../../../../utils/alerts';
import { validationMessages } from '../../../../message/validations';
import CustomInputSingleLine from '../../../components/General/CustomInputSingleLine';
import CustomSelectSingleLine from '../../../components/General/CustomSelectSingleLine';
import AddWorker from '../../../components/Workers/AddWorker/AddWorker';
import ReportService from '../../../../services/report';
import { CODES } from '../../../../utils/codesHTTP';
import _ from 'lodash';
import CustomInputInfoSingleLine from '../../../components/General/CustomInputInfoSingleLine';

const SST = ({ handleCancel, handleOpenAddWorker, dataAreas, valuePlant, valueArea, setValueArea, dataSubAreas,
    suggestionType, stateActionPlan, informationInspection, setInformationInspection, handleOpenLoader }) => {

    const options = informationInspection?.tipo?.opciones;

    useEffect(() => {
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            areaSubArea_id: null,
            supervisor: null,
            supervisor_id: null,
            evaluador: null,
            evaluador_id: null,
        }));
    }, [valuePlant]);

    useEffect(() => {
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            areaSubArea_id: null,
            supervisor: null,
            supervisor_id: null,
            evaluador: null,
            evaluador_id: null,
        }));
    }, [valueArea]);

    const onChangeRespuesta = ({ info, value, key, }) => {
        let updData = _.cloneDeep(informationInspection)
        const respuestaIndex = updData.respuestas.findIndex(respuesta => +respuesta.pregunta_id === +info.id_pregunta);
        const keyAnswer = info.tipo_respuesta.desc2;
        const opciones_id = info.tipo_respuesta.desTipoRespuesta === "opt" ? info?.opciones_id : null;
        const id_pregunta = info.id_pregunta;
        const tipoRespuesta_id = info.tipoRespuesta_id;

        let respueta = {
            inspeccion_id: null,
            pregunta_id: null,
            tipoRespuesta_id: null,
            opciones_id: null,
            opcion_id: null,
            puntaje: null,
            descipcion: null,
            anomalias: null,
        };
        respueta["pregunta_id"] = id_pregunta
        respueta["tipoRespuesta_id"] = tipoRespuesta_id
        respueta["opciones_id"] = opciones_id
        if (info.tipo_respuesta.desTipoRespuesta === 'opt') {
            respueta["puntaje"] = info.puntaje
        }

        const opcion = info.opciones.find(e => +e.value === +value);
        if (respuestaIndex !== -1) {
            updData.respuestas[respuestaIndex][key === 'answer' ? keyAnswer : key] = value;
        } else {
            if (key === 'answer') {
                respueta[keyAnswer] = value
                updData.respuestas.push(respueta);
            } else {
                respueta[key] = value;
                updData.respuestas.push(respueta);
            }
        }
        setInformationInspection(updData);
        if (key === 'answer') {
            onChangePuntajes({ opcion: opcion, puntaje: info.puntaje, id_pregunta: id_pregunta, datos: updData })
        }
    }

    const onChangePuntajes = ({ datos }) => {
        const respuestas = datos.respuestas;
        const tipo_modulos = datos.tipo.tipo_modulos;

        const updatedTipoModulos = tipo_modulos.map(modulo => {
            const totalPreguntasDelModulo = modulo.preguntas.length;
            const { sumaNA, sumaC } = modulo.preguntas.reduce((acc, pregunta) => {
                const options = pregunta?.opciones;
                const respuesta = respuestas.find(e => +e.pregunta_id === +pregunta.id_pregunta);

                const respuestaIsNA = options?.find(opt => +opt.value === +respuesta?.opcion_id)?.label?.toUpperCase() === 'N.A.';
                const respuestaIsC = options?.find(opt => +opt.value === +respuesta?.opcion_id)?.label?.toUpperCase() === 'C.';
                if (respuestaIsNA) {
                    acc.sumaNA += 1;
                }
                if (respuestaIsC) {
                    acc.sumaC += 1;
                }
                return acc;
            }, { sumaNA: 0, sumaC: 0 });

            // const puntajeModulo = sumaC / (totalPreguntasDelModulo - sumaNA) || 0;
            const puntajeModulo = ((sumaC + sumaNA) / totalPreguntasDelModulo) || 0;
            return {
                ...modulo,
                puntajeModulo: (puntajeModulo * 100)?.toFixed(2)
            };
        });
        const totalPuntaje = updatedTipoModulos?.reduce((sum, e) => sum + (+e.puntajeModulo), 0);
        const promedioPuntaje = totalPuntaje / (updatedTipoModulos?.length || 1);
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            tipo: {
                ...prevInformation.tipo,
                tipo_modulos: updatedTipoModulos
            },
            puntaje: promedioPuntaje?.toFixed(2),
        }));
    };

    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const [isStrictClose, setIsStrictClose] = useState(false);
    const openPrompt = (strictClose) => {
        setIsStrictClose(strictClose); //? true es para cerrar solo por boton cerrar, false se cierra precionando en cualquier parte
        setIsPromptOpen(true);
    };

    const closePrompt = () => {
        setIsPromptOpen(false);
    };

    const handleAddActionPlan = () => {
        openPrompt(true);
    };

    const handleFormSubmitActionPlan = (formData) => {
        formData = { ...formData, estadoPlan_id: 1 };
        setInformationInspection(prev => ({
            ...prev,
            planes_accion: [...prev.planes_accion, formData]
        }));
    };

    const [deletedActionPlans, setDeletedActionPlans] = useState([]);
    // Función para eliminar un plan de acción
    const handleDeleteActionPlans = (desPlanAccion) => {
        const ActionPlanToDelete = informationInspection.planes_accion.find(sug => sug.desPlanAccion === desPlanAccion);

        if (ActionPlanToDelete) {
            // Almacena la sugerencia eliminada en el estado
            setDeletedActionPlans(prev => [...prev, ActionPlanToDelete]);

            // Actualiza la lista de sugerencias
            setInformationInspection(prev => ({
                ...prev,
                planes_accion: prev.planes_accion.filter(sug => sug.desPlanAccion !== desPlanAccion)
            }));
        }
    };

    return (
        <section className='form-register inspection'>
            <div className='row justify-content-center'>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Area'}
                        placeholder={'Selecionar Area'}
                        options={dataAreas}
                        value={valueArea || 0}
                        onChange={(value, event) => setValueArea(value)}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Sub-Area'}
                        placeholder={'Selecionar SubArea'}
                        options={dataSubAreas}
                        required={true}
                        value={dataSubAreas?.find(option => option.id_areaSubArea === informationInspection?.areaSubArea_id)}
                        onChange={(value, event) => {
                            const selectedOption = dataSubAreas?.find(option => option.value === value);
                            setInformationInspection({
                                ...informationInspection,
                                areaSubArea_id: selectedOption.id_areaSubArea
                            });
                        }}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col col-12 col-md-6 col-lg-3'>
                    <div className="header-and-btn-end">
                        <div className="text-header">
                            <CustomInputSingleLine
                                title={"Responsable Area"}
                                name={"supervisor_id"}
                                required={true}
                                value={informationInspection?.supervisor?.trabajador || ""}
                                readOnly={false}
                            />
                        </div>
                        <button type='button'
                            className="btn-outline-custom primary btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "supervisor_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
                <div className='col col-12 col-md-6 col-lg-3'>
                    <div className="header-and-btn-end">
                        <div className="text-header">
                            <CustomInputSingleLine
                                title={"Responsable Inspección"}
                                name={"evaluador_id"}
                                required={true}
                                value={informationInspection?.evaluador?.trabajador || ""}
                                readOnly={false}
                            />
                        </div>
                        <button type='button'
                            className="btn-outline-custom primary btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "evaluador_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-3 d-flex'>
                    <div style={{ width: "70%" }}>
                        <CustomInputSingleLine
                            title={"Fecha Inspección"}
                            type={"date"}
                            value={informationInspection?.fecha}
                            name={"fecha"}
                            style={{ width: "80%" }}
                            onChange={(e) => setInformationInspection({ ...informationInspection, fecha: e.target.value })}
                            required={true}
                        />
                    </div>
                    <div style={{ width: "40%" }}>
                        <CustomInputSingleLine
                            type={"time"}
                            value={informationInspection?.hora}
                            name={"hora"}
                            onChange={(e) => setInformationInspection({ ...informationInspection, hora: e.target.value })}
                            required={true}
                        />
                    </div>
                </div>
                <div className='col col-12 col-md-6 col-lg-3'>
                    <CustomInputInfoSingleLine
                        title={"Puntaje"}
                        value={informationInspection?.puntaje || 0}
                        name={"puntaje"}
                    />
                </div>
            </div>
            {/* preguntas */}
            <div className="table-responsive">
                <table className="table table-bordered table-sm custom-table">
                    <tbody>
                        {informationInspection?.tipo?.tipo_modulos.map((module) => {
                            return (
                                <ModuleRow
                                    key={`module-${module.modulo.id_modulo}`}
                                    module={module.modulo}
                                    puntaje={module?.puntajeModulo}
                                    questions={module.preguntas}
                                    informationInspection={informationInspection}
                                    onChange={onChangeRespuesta}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* plan de accion */}
            <div className="table-responsive-sm">
                <table className="table table-bordered table-sm">
                    <thead className="border-white">
                        <tr>
                            <th className="text-center title-table-inspection" colSpan={666} >
                                PLAN DE ACCIÓN
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center title-table-inspection" >SEMANA</th>
                            <th className="text-center title-table-inspection col-6" >ACCION</th>
                            <th className="text-center title-table-inspection" >FECHA FIN</th>
                            <th className="text-center title-table-inspection" >RESPONSABLE</th>
                            <th className="text-center title-table-inspection col-2 row-header-and-btn-end" >
                                <div className="text-header">ESTADO</div>
                                <button className="btn-outline-custom dark btn-header-end" type='button'
                                    onClick={() => handleAddActionPlan()}
                                >
                                    <AddBoxIcon fontSize='small' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationInspection?.planes_accion?.map((plan, idxPlan) => {
                            return (
                                <tr key={idxPlan}>
                                    <td className="text-center col-1">{plan.semana}</td>
                                    <td>{plan.desPlanAccion}</td>
                                    <td className="text-center">{plan.fechaFin}</td>
                                    <td className="text-center">{plan.responsable?.trabajador}</td>
                                    <td className="row-header-and-btn-end">
                                        <div className="text-header">{stateActionPlan?.find(e => +e.value === +plan.estadoPlan_id)?.label}</div>
                                        <button
                                            type='button'
                                            className="btn-outline-custom danger btn-header-end"
                                            onClick={() => handleDeleteActionPlans(plan.desPlanAccion)}
                                        >
                                            <DeleteIcon style={{ fontSize: "15px" }} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center gap-2 mb-3'>
                <button className='btn btn-lg btn-outline-danger' type="button" onClick={handleCancel}>
                    Cancelar
                </button>
                <button className='btn btn-lg btn-outline-success' type="submit">
                    Guardar
                </button>
            </div>
            <div>
                {isPromptOpen && (
                    <FormPlanAction
                        onClose={closePrompt}
                        onSubmit={handleFormSubmitActionPlan}
                        isStrictClose={isStrictClose}
                        valuePlant={valuePlant}
                    />
                )}
            </div>
            {/* Leyenda con las opciones */}
            <div className="mb-5 col-12 col-md-4"
                style={{
                    border: "solid 1px black",
                    borderRadius: "25px",
                    padding: "10px",
                }}
            >
                <table className="table table-bordered table-sm custom-table">
                    <thead>
                        <tr>
                            <th className="text-center" colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>
                                Leyenda de Notas
                            </th>
                        </tr>
                        <tr>
                            {options?.map((row) => (
                                <th key={row.label} className="text-center col-1" style={{ width: "30px" }}>
                                    {row?.desc2}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {options?.map((row) => (
                                <td key={row.label} className="text-center" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                    {row?.label}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </section >
    )
}

const ModuleRow = ({ module, puntaje, questions, informationInspection, onChange }) => {
    return (
        <>
            <tr key={`module-${module.id_modulo}`}>
                <td className="text-center" colSpan={2} style={{ background: "#BFBFBF", fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{module.desModulo}</td>
                <td style={{ fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{`% Cumplimiento: ${puntaje || ''} %`}</td>
            </tr>
            {questions.map((question) => {
                const answer = informationInspection.respuestas.find(e => +e.pregunta_id === +question.id_pregunta);
                return (
                    <QuestionRow
                        key={`question-${question.id_pregunta}`}
                        question={question}
                        answer={answer}
                        onChange={onChange}
                    />
                );
            })}
        </>
    );
};

const QuestionRow = ({ question, answer, onChange }) => {
    const tipeRespuesta = question.tipo_respuesta;
    const options = question.opciones;
    return (
        <tr>
            <td>{question.desPregunta}</td>
            <td className='text-center'>{question.puntaje}</td>
            <td>
                {tipeRespuesta.desTipoRespuesta === 'opt' ?
                    <div className="form-control custom-radio-group in-line">
                        {options.map((option) => {
                            return (
                                <div key={option.value} className="custom-radio-option">
                                    <input
                                        type="radio"
                                        id={`${question.id_pregunta}_${option.value}`}
                                        value={option.value}
                                        checked={answer ? answer[tipeRespuesta.desc2] === option.value : false}
                                        onChange={(e) => onChange({ info: question, value: e.target.value, key: "answer" })}
                                        required
                                    />
                                    <label htmlFor={`${question.id_pregunta}_${option.value}`}>{option.label}</label>
                                </div>
                            )
                        })}
                    </div>
                    : <input
                        className="form-control custom-input"
                        placeholder="Ingrese valor"
                        type={tipeRespuesta.desTipoRespuesta}
                        onChange={(e) => onChange({ info: question, value: e.target.value, key: "answer" })}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        required
                    />
                }
            </td>
        </tr>
    );
};

const FormPlanAction = ({ onClose, onSubmit, isStrictClose, valuePlant }) => {
    const [openAddWorkersModal, setOpenAddWorkersModal] = useState(false);
    const [semana, setSemana] = useState('');
    const [desPlanAccion, setDesPlanAccion] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [responsableId, setResponsableId] = useState('');
    const [responsable, setResponsable] = useState({});
    const [workersSelected, setWorkersSelected] = useState([]);



    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // onSubmit({ semana, desPlanAccion, fechaFin, responsable_id: responsableId });
    //     // handleClose(); // Cierra el modal
    // };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validación manual de campos requeridos
        if (!semana || !desPlanAccion || !fechaFin || !responsableId) {
            alert('Todos los campos son obligatorios.');
            return;
        }

        onSubmit({ semana, desPlanAccion, fechaFin, responsable, responsable_id: responsableId, });
        handleClose(); // Cierra el modal
    };

    const handleClose = () => {
        setSemana('');
        setDesPlanAccion('');
        setFechaFin('');
        setResponsableId('');
        onClose();
    };

    const handleOverlayClick = (e) => {
        if (!isStrictClose && e.target.className.includes('modal-overlay-prompt')) {
            handleClose();
        }
    };

    const handleOpenAddWorker = ({ key }) => {
        if (!valuePlant) {
            simpleAlerts({ message: validationMessages.planta, type: "warning" });
            return;
        }
        setOpenAddWorkersModal(true);
    }

    useEffect(() => {
        if (workersSelected && workersSelected?.length > 0) {
            setResponsableId(workersSelected[0]?.id_persona);
            setResponsable(workersSelected[0]);
        }
    }, [workersSelected])

    return (
        <div className="modal-overlay-prompt" onClick={handleOverlayClick}>
            <AddWorker
                open={openAddWorkersModal}
                setOpen={setOpenAddWorkersModal}
                typeSelection={"SINGLE"}
                valueTypeWorker={1}
                valuePlantAux={valuePlant}
                workersSelected={workersSelected}
                setWorkersSelected={setWorkersSelected}
            />
            <div className="input-container-prompt">
                <h2 className="title-prompt">Nuevo Plan de Acción</h2>
                {/* <form onSubmit={handleSubmit}> */}
                <div className="form-group-prompt">
                    <label htmlFor="semana">Semana:</label>
                    <input
                        type="number"
                        id="semana"
                        placeholder="Ingrese semana"
                        value={semana}
                        onChange={(e) => setSemana(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group-prompt">
                    <label htmlFor="desPlanAccion">Descripción del Plan:</label>
                    <input
                        type="text"
                        id="desPlanAccion"
                        placeholder="Ingrese descripción"
                        value={desPlanAccion}
                        onChange={(e) => setDesPlanAccion(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group-prompt">
                    <label htmlFor="fechaFin">Fecha de Fin:</label>
                    <input
                        type="date"
                        id="fechaFin"
                        value={fechaFin}
                        onChange={(e) => setFechaFin(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group-prompt">
                    <label htmlFor="responsableId">Responsable:</label>
                    <div className="header-and-btn-end">
                        <input
                            type="text"
                            id="responsable"
                            value={responsable?.trabajador}
                            required
                            readOnly={true}
                        />
                        <button type='button'
                            className="btn-outline-custom btn-submit-prompt btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "responsable_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
                <div className="modal-buttons-prompt">
                    <button type="button" className="btn-submit-prompt" onClick={handleSubmit}>Agregar</button>
                    <button type="button" onClick={handleClose} className="btn-cancel-prompt">Cancelar</button>
                </div>
                {/* </form> */}
            </div>

        </div>


    );
};

export default SST;