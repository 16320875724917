import React, { useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import SearchIcon from '@mui/icons-material/Search';
import UnsafeConditionService from '../../../services/unsafeCondition';
import BodyUnsafeCondition from '../../components/UnsafeCondition/BodyUnsafeCondition';
import CustomInput from '../../components/General/CustomInput';
import { getDateOL } from '../../../utils/function';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { titlesAlerts } from '../../../message/titlesAlerts';

const UnsafeConditionClosure = () => {
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [dateInit, setDateInit] = useState(getDateOL(""));
    const [dateEnd, setDateEnd] = useState(getDateOL(""));

    const [dataConditions, setDataConditions] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showUnsafeCondition(),
        ]).then(() => handleOpenLoader(false));
    }

    const showUnsafeCondition = async () => {
        if (!dateInit || dateInit === "") {
            simpleAlerts({ message: validationMessages.dateInit })
            return;
        }
        if (!dateEnd || dateEnd === "") {
            simpleAlerts({ message: validationMessages.dateEnd })
            return;
        }
        
        try {
            const params = {
                fechaInicio: dateInit,
                fechaFin: dateEnd,
            };
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.condicionInsegura_list);
            setDataConditions(null);
            const result = await UnsafeConditionService.showAll(params);
            setDataConditions(result.data);
            if (result.data?.length <= 0) {
                simpleAlerts({message:titlesAlerts.not_found_unsafe_condition})
            }
            return [];
        } catch (error) {
            console.log(`Error en UnsafeCondition showAll: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    return (
        <div>
            <h1 className='text-center title-page'>Cierre Condición Insegura</h1>
            <section className='form-register'>
                <div className='row justify-content-center align-items-end gap-2'>
                    <div className='col-md-3 col-sm-4'>
                        <CustomInput
                            title={'Fecha Inicio'}
                            value={dateInit}
                            type={'date'}
                            onChange={(e) => setDateInit(e.target.value)}
                        />
                    </div>
                    <div className='col-md-3 col-sm-4'>
                        <CustomInput
                            title={'Fecha Fin'}
                            value={dateEnd}
                            type={'date'}
                            onChange={(e) => setDateEnd(e.target.value)}
                        />
                    </div>
                    <div className='col-2'>
                        <button type='button' className="btn btn-sm btn-outline-success" title='Buscar condición' onClick={showUnsafeCondition} ><SearchIcon /></button>
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-10 col-sm-11'>
                        <BodyUnsafeCondition
                            getInfo={showUnsafeCondition}
                            data={dataConditions}
                            setData={setDataConditions}
                            id={"id_condicInsegu"}
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default UnsafeConditionClosure